import PropTypes from 'prop-types';
import { createContext, FC, useContext } from 'react';

import Loading from '../Loading';
import Maintenance from '../Maintenance';

import {
  OrganizationQuery,
  useOrganizationQuery,
} from './Organization.generated';

type Organization = OrganizationQuery['organization'];

export const OrganizationContext = createContext<Organization | undefined>(
  undefined,
);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);

  if (!organization) {
    throw new Error('No organization');
  }

  return organization;
};

export const useOrganizationDomains = (): Organization['domains'] => {
  const { domains } = useOrganization();

  if (!domains) {
    throw new Error('No organization domains');
  }

  return domains;
};

export const useOrganizationSettings = (): Organization['whiteLabel'] => {
  const { whiteLabel: settings } = useOrganization();

  if (!settings) {
    throw new Error('No organization settings');
  }

  return settings;
};

const OrganizationProvider: FC = ({ children }) => {
  const { data, error, loading } = useOrganizationQuery();

  const organization = data?.organization;

  if (error) {
    return <Maintenance />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.propTypes = {
  children: PropTypes.node,
};

OrganizationProvider.displayName = 'OrganizationProvider';

export default OrganizationProvider;
