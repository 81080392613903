import { ErrorBoundary, withProfiler } from '@sentry/react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AntConfigProvider from '../AntConfigProvider';
import App from '../App';
import AuthenticationProvider from '../AuthenticationProvider';
import ConfigProvider from '../ConfigProvider';
import DatagonProvider from '../DatagonProvider';
import EnvironmentProvider from '../EnvironmentProvider';
import GoogleAnalytics from '../GoogleAnalytics';
import GraphQLProvider from '../GraphQLProvider';
import Hotjar from '../Hotjar';
import Loading from '../Loading';
import OrganizationProvider from '../OrganizationProvider';
import withException from '../withException';

import ErrorBoundaryFallback from './ErrorBoundaryFallback';

import './styles.less';

const Root = () => (
  <ErrorBoundary fallback={ErrorBoundaryFallback}>
    <BrowserRouter>
      <EnvironmentProvider>
        <DatagonProvider>
          <ConfigProvider>
            <GraphQLProvider>
              <OrganizationProvider>
                <Hotjar />
                <GoogleAnalytics />
                <AuthenticationProvider>
                  <AntConfigProvider>
                    <Suspense fallback={<Loading />}>
                      <App />
                    </Suspense>
                  </AntConfigProvider>
                </AuthenticationProvider>
              </OrganizationProvider>
            </GraphQLProvider>
          </ConfigProvider>
        </DatagonProvider>
      </EnvironmentProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default withProfiler(withException(Root));
