import { ConfigProvider, message } from 'antd';
import en from 'antd/es/locale/en_US';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FC } from 'react';

// https://ant.design/components/date-picker/#How-to-modify-start-day-of-week
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export const maxMessages = 5;

message.config({
  maxCount: maxMessages,
});

const locale = {
  ...en,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  Pagination: {
    ...en.Pagination,
    jump_to: 'Page #',
  },
};

const form = {
  validateMessages: {
    required: 'Required',
  },
};

const pageHeader = {
  ghost: false,
};

const AntConfigProvider: FC = ({ children }) => {
  return (
    <ConfigProvider form={form} locale={locale} pageHeader={pageHeader}>
      {children}
    </ConfigProvider>
  );
};

AntConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AntConfigProvider;
