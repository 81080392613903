import { init, reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useSessionStorageState } from 'ahooks';
import PropTypes from 'prop-types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';

import Environment from './Environment';

type EnvironmentState = [Environment | undefined, (value: Environment) => void];

export const EnvironmentContext = createContext<EnvironmentState>([
  undefined,
  () => null,
]);

export const useEnvironment = (): EnvironmentState =>
  useContext(EnvironmentContext);

const EnvironmentProvider: FC = ({ children }) => {
  const history = useHistory();
  const [environment, setEnvironment] = useSessionStorageState<
    Environment | undefined
  >('environment');

  useEffect(() => {
    const sentryEnvironment = environment || process.env.NODE_ENV;

    init({
      dsn: 'https://77d921620545458191329a9011c25c45@sentry.io/1301135',
      enabled: sentryEnvironment === Environment.Production,
      environment: sentryEnvironment,
      ignoreErrors: [
        'ResizeObserver loop',
        'useRequest has caught the exception',
      ],
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: reactRouterV5Instrumentation(history),
        }),
      ],
      normalizeDepth: 10,
      release: `cockpit-${VERSION}`,
      tracesSampleRate: 0.1,
    });
  }, [environment, history]);

  const setPersistedEnvironment = useCallback(
    (value: Environment) => {
      setEnvironment(value);
    },
    [setEnvironment],
  );

  const value = useMemo<EnvironmentState>(
    () => [environment, setPersistedEnvironment],
    [environment, setPersistedEnvironment],
  );

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = {
  children: PropTypes.node,
};

EnvironmentProvider.displayName = 'EnvironmentProvider';

export default EnvironmentProvider;
