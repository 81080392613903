import { Spin } from 'antd';
import type { SpinProps } from 'antd/es/spin';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './styles.module.less';

const Loading: FC<SpinProps> = ({ className, ...rest }) => (
  <Spin className={classNames(styles.spin, className)} size="large" {...rest} />
);

export default Loading;
