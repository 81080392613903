import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type OrganizationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrganizationQuery = { __typename?: 'Query' } & {
  organization: { __typename?: 'Organization' } & Pick<
    Types.Organization,
    'id' | 'name' | 'legalEntity' | 'supportEmail'
  > & {
      whiteLabel: { __typename?: 'OrganizationWhiteLabel' } & Pick<
        Types.OrganizationWhiteLabel,
        'backgroundColor' | 'logoUrl' | 'primaryColor' | 'profitGoal'
      >;
      domains: { __typename?: 'OrganizationDomains' } & Pick<
        Types.OrganizationDomains,
        | 'appUrl'
        | 'farmerUrl'
        | 'feedUrl'
        | 'partnerUrl'
        | 'postbackUrl'
        | 'supersetUrl'
        | 'surveyorUrl'
        | 'trackingUrl'
        | 'turniloUrl'
      >;
    };
};

export const OrganizationDocument = gql`
  query Organization {
    organization {
      id
      name
      legalEntity
      supportEmail
      whiteLabel {
        backgroundColor
        logoUrl
        primaryColor
        profitGoal
      }
      domains {
        appUrl
        farmerUrl
        feedUrl
        partnerUrl
        postbackUrl
        supersetUrl
        surveyorUrl
        trackingUrl
        turniloUrl
      }
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    baseOptions,
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    baseOptions,
  );
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>;
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
