import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import PropTypes from 'prop-types';
import { FC, useEffect, useMemo } from 'react';

import { useAuthentication } from '../AuthenticationProvider';
import { useConfig } from '../ConfigProvider';

import authLink, { setAuthorization } from './authLink';
import omitTypeNameLink from './omitTypeNameLink';
import sentryLink from './sentryLink';
import typePolicies from './typePolicies';
import useErrorLink from './useErrorLink';

const GraphQLProvider: FC = ({ children }) => {
  const { accessToken } = useAuthentication();
  const { datagonUrl } = useConfig();

  useEffect(() => {
    if (accessToken) {
      setAuthorization(accessToken);
    }
  }, [accessToken]);

  const cache = useMemo(() => new InMemoryCache({ typePolicies }), []);

  const errorLink = useErrorLink();

  const client = useMemo(() => {
    const httpLink = new HttpLink({ uri: datagonUrl });

    const link = authLink.concat(
      ApolloLink.from([omitTypeNameLink, errorLink, sentryLink, httpLink]),
    );

    return new ApolloClient({
      cache,
      connectToDevTools: true,
      link,
      queryDeduplication: false,
    });
  }, [cache, datagonUrl, errorLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphQLProvider.propTypes = {
  children: PropTypes.node,
};

export default GraphQLProvider;
