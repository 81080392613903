import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'qs';

const instance = axios.create({
  paramsSerializer: stringify,
});

let api: Record<string, string>;
let authorization: string;

export const initRequest = ({
  accessToken,
  api: apiOption,
}: {
  accessToken: string;
  api: Record<string, string>;
}): void => {
  api = apiOption;
  authorization = `Bearer ${accessToken}`;
};

const request = <D>(config: AxiosRequestConfig) =>
  instance(config).then((res: AxiosResponse<D>) => res.data);

export const geonames = <D>(config: AxiosRequestConfig): Promise<D> =>
  request({
    baseURL: api.geonames,
    // API for Geonames requires query request params array formatted as: featureCode=ADM1&featureCode=PPLA&featureCode=PPLA2&featureCode=PPLA3&featureCode=PPLA
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
    ...config,
  });

export const surveyor = <D>(config: AxiosRequestConfig): Promise<D> =>
  request({
    baseURL: api.surveyor,
    headers: { authorization },
    ...config,
  });

export const farmer = <D>(config: AxiosRequestConfig): Promise<D> =>
  request({
    baseURL: api.farmer,
    headers: { authorization },
    ...config,
  });
