import { captureException } from '@sentry/react';
import { useRequest } from 'ahooks';
import { parseDomain, ParseResultType } from 'parse-domain';
import PropTypes from 'prop-types';
import { createContext, FC, useContext, useMemo } from 'react';

import Loading from '../Loading';
import Maintenance from '../Maintenance';

export const ConfigContext = createContext<string | undefined>(undefined);

export const useOrganizationUrl = (): string => {
  const origin = window.location.origin;
  const baseUrl = 'https://organizations-config.internal.swaarm.com';

  // Try to guess the environemnt based on the domain
  if (
    (origin.includes('internal') && !origin.includes('demo')) ||
    origin.includes('localhost')
  ) {
    return `${baseUrl}/staging`;
  }

  return `${baseUrl}/production`;
};

export const useDatagonUrl = (): string => {
  const datagonUrl = useContext(ConfigContext);

  if (!datagonUrl) {
    throw new Error('No datagon');
  }

  return datagonUrl;
};

const DatagonProvider: FC = ({ children }) => {
  const organizationUrl = useOrganizationUrl();
  const domain = useMemo(() => {
    const { hostname } = window.location;

    // Specific environment for Rokey to test farmer
    if (hostname.includes('rokey.swaarm.com')) {
      return 'rokey.theiris';
    }

    const parseResult = parseDomain(hostname);

    if (parseResult.type === ParseResultType.Listed && parseResult.domain) {
      if (
        parseResult.domain === 'swaarm-clients' &&
        parseResult.subDomains.length > 0
      ) {
        return parseResult.subDomains[parseResult.subDomains.length - 1];
      }

      return parseResult.domain;
    }

    return 'swaarm';
  }, []);

  const { data: organization, error, loading } = useRequest<{
    datagonUrl: string;
  }>(`${organizationUrl}/${domain}.json?cache=${CACHE}`, {
    onError: (error) => {
      captureException(error);
    },
    refreshDeps: [domain],
  });

  const datagonUrl = organization?.datagonUrl;

  if (error) {
    return <Maintenance />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <ConfigContext.Provider value={datagonUrl}>
      {children}
    </ConfigContext.Provider>
  );
};

DatagonProvider.propTypes = {
  children: PropTypes.node,
};

DatagonProvider.displayName = 'DatagonProvider';

export default DatagonProvider;
