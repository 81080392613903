import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
export type AuthFragment = { __typename?: 'AuthResponse' } & Pick<
  Types.AuthResponse,
  'authChallenge'
> & {
    user: Types.Maybe<
      { __typename?: 'User' } & Pick<
        Types.User,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'email'
        | 'permissions'
        | 'roles'
        | 'status'
      >
    >;
    token: Types.Maybe<
      { __typename?: 'AuthToken' } & Pick<
        Types.AuthToken,
        'accessToken' | 'expiresIn' | 'refreshToken'
      >
    >;
  };

export const AuthFragmentDoc = gql`
  fragment Auth on AuthResponse {
    authChallenge
    user {
      id
      firstName
      lastName
      email
      permissions
      roles
      status
    }
    token {
      accessToken
      expiresIn
      refreshToken
    }
  }
`;
