import { FetchResult } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { initRequest } from '../../api/request';
import {
  AuthChangePasswordNewUserInput,
  AuthToken,
} from '../../graphql.generated';
import paths from '../../paths.json';
import { useConfig } from '../ConfigProvider';
import { setAuthorization } from '../GraphQLProvider/authLink';
import { useOrganizationDomains } from '../OrganizationProvider';

import {
  ChangePasswordMutation,
  useChangePasswordMutation,
} from './ChangePassword.generated';
import {
  ForgotPasswordMutation,
  useForgotPasswordMutation,
} from './ForgotPassword.generated';
import { LoginMutation, useLoginMutation } from './Login.generated';
import {
  RefreshTokenMutation,
  useRefreshTokenMutation,
} from './RefreshToken.generated';
import {
  ResetPasswordMutation,
  useResetPasswordMutation,
} from './ResetPassword.generated';

type Authentication = {
  accessToken?: string;
  authenticated: boolean;
  forgotPassword: (
    email: string,
  ) => Promise<FetchResult<ForgotPasswordMutation>>;
  resetPassword: (
    email: string,
    password: string,
    token: string,
  ) => Promise<FetchResult<ResetPasswordMutation>>;
  changePassword: (
    input: AuthChangePasswordNewUserInput,
  ) => Promise<FetchResult<ChangePasswordMutation>>;
  login: (
    username: string,
    password: string,
  ) => Promise<FetchResult<LoginMutation>>;
  logout: () => void;
  refreshToken?: string;
  renewToken: () => Promise<FetchResult<RefreshTokenMutation>>;
};

type AuthenticationState = Pick<AuthToken, 'accessToken' | 'refreshToken'> & {
  expiresAt: number;
};

const contextError = () => {
  throw new Error('No <AuthenticationProvider />');
};

const contextErrorPromise = () => Promise.reject(contextError());

const AuthenticationContext = createContext<Authentication>({
  authenticated: false,
  changePassword: contextErrorPromise,
  forgotPassword: contextErrorPromise,
  login: contextErrorPromise,
  logout: () => undefined,
  renewToken: contextErrorPromise,
  resetPassword: contextErrorPromise,
});

export const useAuthentication = (): Authentication =>
  useContext(AuthenticationContext);

const getExpiresAt = (expiresIn: number) =>
  new Date(Date.now() + expiresIn * 1e3).getTime();

const AuthenticationProvider: FC = ({ children }) => {
  const config = useConfig();
  const history = useHistory();
  const { farmerUrl, surveyorUrl } = useOrganizationDomains();

  const [authentication, setAuthenticationState] = useState<
    AuthenticationState | undefined
  >(() => {
    const item = localStorage.getItem('authentication');

    if (!item) {
      return;
    }

    try {
      return JSON.parse(item) as AuthenticationState;
    } catch (e) {
      return undefined;
    }
  });

  const setAuthentication = useCallback(
    (result: Pick<AuthToken, 'accessToken' | 'expiresIn' | 'refreshToken'>) => {
      setAuthenticationState({
        accessToken: result.accessToken,
        expiresAt: getExpiresAt(result.expiresIn),
        refreshToken: result.refreshToken,
      });
    },
    [],
  );

  const [_login] = useLoginMutation({
    onCompleted: (data) => {
      const token = data.login?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [_changePassword] = useChangePasswordMutation({
    onCompleted: (data) => {
      const token = data.changePasswordNewUser?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [_forgotPassword] = useForgotPasswordMutation();
  const [_resetPassword] = useResetPasswordMutation({
    onCompleted: (data) => {
      const token = data.resetPassword?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });
  const [_renewToken] = useRefreshTokenMutation({
    onCompleted: (data) => {
      const token = data.refreshToken?.token;
      if (token) {
        setAuthentication(token);
      }
    },
  });

  const timeoutRef = useRef<number | null>(null);

  const authenticated = useMemo(() => {
    if (!authentication) {
      return false;
    }

    return Date.now() <= new Date(authentication.expiresAt).getTime();
  }, [authentication]);

  const login = useCallback(
    (username: string, password: string) =>
      _login({ variables: { input: { password, username } } }),
    [_login],
  );

  const changePassword = useCallback(
    (values: AuthChangePasswordNewUserInput) =>
      _changePassword({ variables: { input: values } }),
    [_changePassword],
  );

  const forgotPassword = useCallback(
    (email: string) => _forgotPassword({ variables: { email } }),
    [_forgotPassword],
  );

  const resetPassword = useCallback(
    (email: string, password: string, token: string) =>
      _resetPassword({ variables: { email, password, token } }),
    [_resetPassword],
  );

  const logout = useCallback(() => {
    setAuthenticationState(undefined);
    history.push(paths.login);
  }, [history]);

  const renewToken = useCallback(() => {
    timeoutRef.current = null;

    if (authentication?.refreshToken) {
      return _renewToken({
        variables: { input: { refreshToken: authentication?.refreshToken } },
      });
    }

    throw Error('No Refresh Token');
  }, [_renewToken, authentication?.refreshToken]);

  useEffect(() => {
    if (!authentication) {
      localStorage.removeItem('authentication');

      return;
    }

    localStorage.setItem('authentication', JSON.stringify(authentication));

    timeoutRef.current = window.setTimeout(
      () => void renewToken()?.catch(console.warn),
      Math.max(0, authentication.expiresAt - Date.now() - 36e5),
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [authentication, renewToken]);

  useEffect(() => {
    if (authentication?.accessToken) {
      initRequest({
        accessToken: authentication.accessToken,
        api: {
          farmer: farmerUrl,
          geonames: config.geonamesUrl,
          surveyor: surveyorUrl,
        },
      });
      setAuthorization(authentication.accessToken);
    }
  }, [config.geonamesUrl, authentication?.accessToken, farmerUrl, surveyorUrl]);

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken: authentication?.accessToken,
        authenticated,
        changePassword,
        forgotPassword,
        login,
        logout,
        renewToken,
        resetPassword,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthenticationProvider.displayName = 'AuthenticationProvider';

export default AuthenticationProvider;
