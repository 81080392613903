import type { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import AuthException from '../../constants/AuthException';
import routes from '../../paths.json';
import showErrorNotification from '../../utils/showErrorNotification';

const useErrorLink = (): ApolloLink => {
  const history = useHistory();

  return useMemo(
    () =>
      onError((errorResponse) => {
        const { graphQLErrors, operation } = errorResponse;

        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            const { message } = error;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const type = error.type as string;

            if (type in AuthException) {
              return;
            }

            if (type === 'AccessDeniedException') {
              history.replace(routes.login, {
                error: 'Access denied.',
              });

              return;
            }

            Sentry.withScope((scope) => {
              scope.setExtras({
                ...error,
                query: operation.query.loc?.source.body,
                variables: JSON.stringify(operation.variables, null, '  '),
              });

              Sentry.captureException(new Error(message));
            });

            showErrorNotification({
              description: message,
              message: type,
            });
          });
        }
      }),
    [history],
  );
};

export default useErrorLink;
