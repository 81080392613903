import { useFavicon } from 'ahooks';
import { lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import logo from '../../logo.png';
import paths from '../../paths.json';
import { useOrganizationSettings } from '../OrganizationProvider';

const Auth = lazy(() => import('../../routes/Auth'));

const Home = lazy(() => import('../../routes/Home'));

const Welcome = lazy(() => import('../../routes/Welcome'));

const App = () => {
  const { pathname } = useLocation();
  const { logoUrl } = useOrganizationSettings();

  useFavicon(logoUrl || logo);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path={[paths.login, paths.changePassword, paths.resetPassword]}>
        <Welcome />
      </Route>
      <Route path={paths.auth}>
        <Auth />
      </Route>
      <Route path={paths.home}>
        <Home />
      </Route>
    </Switch>
  );
};

App.displayName = 'App';

export default App;
