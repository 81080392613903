import { ApolloLink, Operation } from '@apollo/client';

const omitTypename = (key: string, value: unknown) =>
  key === '__typename' ? undefined : value;

// inspired by https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
const omitTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    ) as Operation['variables'];
  }

  return forward(operation).map((data) => data);
});

export default omitTypeNameLink;
