import { useEffect } from 'react';

const hotjarId = 2131433;

const Hotjar = () => {
  const hostname = window.location.hostname;

  useEffect(() => {
    if (hostname.includes('swaarm-clients.com')) {
      // prettier-ignore
      /* eslint-disable */
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:hotjarId,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      /* eslint-enable */
    }
  }, [hostname]);

  return null;
};

export default Hotjar;
