import { Reference, StoreObject, TypePolicies } from '@apollo/client';

import {
  BlacklistedSubId,
  ClickBudget,
  Filler,
  MigrationState,
  Payout,
  PayoutMargin,
  PublisherMargin,
  RecyclingPartner,
  SearchEntity,
  ServiceConfig,
} from '../../graphql.generated';

const typePolicies: TypePolicies = {
  BlacklistedSubId: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('BlacklistedSubId: no "__typename"');

        return;
      }

      const blacklistedSubId = object as Partial<BlacklistedSubId>;

      if (!blacklistedSubId.offer?.id) {
        console.error(`${typename}: "offer.id" is required for caching`);

        return;
      }

      if (!blacklistedSubId.publisher?.id) {
        console.error(`${typename}: "publisher.id" is required for caching`);

        return;
      }

      if (!blacklistedSubId.subId) {
        console.error(`${typename}: "subId" is required for caching`);

        return;
      }

      return `${typename}:${blacklistedSubId.offer.id}:${blacklistedSubId.publisher?.id}:${blacklistedSubId.subId}`;
    },
  },

  ClickBudget: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('ClickBudget: no "__typename"');

        return;
      }

      const clickBudget = object as Partial<ClickBudget>;

      if (clickBudget.advertiser?.id === undefined) {
        console.error(`${typename}: "advertiser.id" is required for caching`);

        return;
      }

      if (clickBudget.offer?.id === undefined) {
        console.error(`${typename}: "offer.id" is required for caching`);

        return;
      }

      if (clickBudget.validity === undefined) {
        console.error(`${typename}: "validity" is required for caching`);

        return;
      }

      if (clickBudget.publisher) {
        return `${typename}:${clickBudget.offer.id}:${
          clickBudget.advertiser.id
        }:${clickBudget.publisher.id}:${String(clickBudget.validity)}`;
      }

      return `${typename}:${clickBudget.offer.id}:${
        clickBudget.advertiser.id
      }:${String(clickBudget.validity)}`;
    },
  },

  Filler: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('Filler: no "__typename"');

        return;
      }

      const filler = object as Partial<Filler>;

      if (filler.fieldName === undefined) {
        console.error(`${typename}: "fieldName" is required for caching`);

        return;
      }

      const advertiserId = String(filler.advertiser?.id);
      const country = String(filler.country);
      const offerId = String(filler.offer?.id);
      const os = String(filler.os);
      const publisherId = String(filler.publisher?.id);

      return `${typename}:${advertiserId}:${country}:${filler.fieldName}:${offerId}:${os}:${publisherId}`;
    },
  },

  MigrationState: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('MigrationState: no "__typename"');

        return;
      }

      const migrationState = object as Partial<MigrationState>;

      if (migrationState.advertiser?.id === undefined) {
        console.error(`${typename}: "advertiser.id" is required for caching`);

        return;
      }

      const advertiserId = String(migrationState.advertiser?.id);

      return `${typename}:${advertiserId}`;
    },
  },

  Payout: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('Payout: no "__typename"');

        return;
      }

      const payout = object as Partial<Payout>;

      if (
        payout.offer === undefined ||
        (payout.offer && payout.offer.id === undefined)
      ) {
        console.error(
          `${typename}: "offer.id" is required for caching`,
          object,
        );

        return;
      }

      if (
        payout.publisher === undefined ||
        (payout.publisher && payout.publisher.id === undefined)
      ) {
        console.error(
          `${typename}: "publisher.id" is required for caching`,
          object,
        );

        return;
      }

      if (
        payout.eventType === undefined ||
        (payout.eventType && payout.eventType.id === undefined)
      ) {
        console.error(
          `${typename}: "eventType" is required for caching`,
          object,
        );

        return;
      }

      const offerId = String(payout.offer?.id || null);
      const publisherId = String(payout.publisher?.id || null);
      const eventTypeId = String(payout.eventType?.id || null);

      return `${typename}:${offerId}:${publisherId}:${eventTypeId}`;
    },
  },

  PayoutMargin: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('PayoutMargin: no "__typename"');

        return;
      }

      const payoutMargin = object as Partial<PayoutMargin>;

      if (payoutMargin.advertiserId === undefined) {
        console.error(
          `${typename}: "advertiserId" is required for caching`,
          object,
        );

        return;
      }

      if (payoutMargin.offerId === undefined) {
        console.error(`${typename}: "offerId" is required for caching`, object);

        return;
      }

      if (payoutMargin.publisherId === undefined) {
        console.error(
          `${typename}: "publisherId" is required for caching`,
          object,
        );

        return;
      }

      const advertiserId = String(payoutMargin.advertiserId);
      const offerId = String(payoutMargin.offerId);
      const publisherId = String(payoutMargin.publisherId);

      return `${typename}:${advertiserId}:${offerId}:${publisherId}`;
    },
  },

  PublisherMargin: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('PublisherMargin: no "__typename"');

        return;
      }

      const publisherMargin = object as Partial<PublisherMargin>;

      if (publisherMargin.publisherId === undefined) {
        console.error(
          `${typename}: "publisherId" is required for caching`,
          object,
        );
      }

      const publisherId = String(publisherMargin.publisherId);

      return `${typename}:${publisherId}`;
    },
  },

  Query: {
    fields: {
      forms: {
        merge(existing: Reference[], incoming: Reference[]): Reference[] {
          return incoming;
        },
      },
    },
  },

  RecyclingPartner: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('RecyclingPartner: no "__typename"');

        return;
      }

      const recyclingPartner = object as Partial<RecyclingPartner>;

      if (recyclingPartner.offer?.id === undefined) {
        console.error(
          `${typename}: "offer.id" is required for caching`,
          object,
        );

        return;
      }

      if (recyclingPartner.publisher?.id === undefined) {
        console.error(
          `${typename}: "publisher.id" is required for caching`,
          object,
        );

        return;
      }

      return `${typename}:${recyclingPartner.publisher.id}:${recyclingPartner.offer.id}`;
    },
  },

  SearchEntity: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('RecyclingPartner: no "__typename"');

        return;
      }

      const searchEntity = object as Partial<SearchEntity>;

      if (searchEntity.type === undefined) {
        console.error(`${typename}: "type" is required for caching`, object);
      }

      return `${typename}:${String(searchEntity.type)}:${String(
        searchEntity.id,
      )}`;
    },
  },

  ServiceConfig: {
    keyFields: (object: Readonly<StoreObject>): string | undefined => {
      const { __typename: typename } = object;

      if (!typename) {
        console.error('ServiceConfig: no "__typename"');

        return;
      }

      const serviceConfig = object as Partial<ServiceConfig>;

      if (serviceConfig.key === undefined) {
        console.error(`${typename}: "key" is required for caching`);

        return;
      }

      if (serviceConfig.service === undefined) {
        console.error(`${typename}: "service" is required for caching`);

        return;
      }

      const key = String(serviceConfig.key);
      const service = String(serviceConfig.service);

      return `${typename}:${key}:${service}`;
    },
  },
};

export default typePolicies;
