import * as Types from '../../graphql.generated';

import { AuthFragment } from './Auth.fragment.generated';
import { gql } from '@apollo/client';
import { AuthFragmentDoc } from './Auth.fragment.generated';
import * as Apollo from '@apollo/client';
export type ChangePasswordMutationVariables = Types.Exact<{
  input: Types.AuthChangePasswordNewUserInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePasswordNewUser: { __typename?: 'AuthResponse' } & AuthFragment;
};

export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: AuthChangePasswordNewUserInput!) {
    changePasswordNewUser(input: $input) {
      ...Auth
    }
  }
  ${AuthFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, baseOptions);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
